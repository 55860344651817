const environmentMethod = (): string => {
  let apiUrl = 'https://sophiadev.phiagroup.com';
  const windowHostName = window.location.hostname;
  if(windowHostName !== 'sophiadev.phiagroup.com') {
    apiUrl = 'https://sophiadev.cognida.ai';
  };
  return apiUrl;
} 

export const environment = {
  production: false,
  baseUrl: environmentMethod(),
  token: '4569595d19c22a0ba8f4879f4db872f05f710585',
  // baseUrl: 'http://localhost:1024',
};